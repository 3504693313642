import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { States } from '../../../../services/services';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function processCall<T>(logger: ILoggerBase, userData: States.IPersonDataStore, force, name: string,
    apiCall: (lastsync) => Promise<any>, processResult: (T, dtSync?: Date) => any, progresscallback: any) {
    const lastsync = userData.lastsyncs.data.find((s) => s.key === name);
    const newDtSync = new Date();
    let dtSync;
    if (lastsync) {
        if (typeof lastsync.date === "string") {
            dtSync = new Date(lastsync.date as string);
        } else {
            dtSync = lastsync.date;
        }
    }

    const endSync = () => {
        let lastsync2 = userData.lastsyncs.data.find((s) => s.key === name);
        const insertLastSync = !lastsync2;
        if (!lastsync2) {
            lastsync2 = {
                date: newDtSync,
                key: name
            } as any;
        } else {
            lastsync2.date = newDtSync;
        }

        if (insertLastSync) {
            userData.lastsyncs.insert(lastsync2);
        } else {
            userData.lastsyncs.update(lastsync2);
        }
    };

    return apiCall(force ? null : dtSync).then((result: any) => {
        if (result /* && (!result.forEach || result.length) */) {
            logger.debug("received " + name + " " + result.length);
            const res = processResult(result, dtSync);
            if (res && res.then) {
                return res.then(() => {
                    endSync();
                });
            }
            endSync();
        } else {
            logger.debug("received " + name + " 0");
        }
    }).then(progresscallback, (err) => {
        if (progresscallback) progresscallback();

        return Promise.reject(err);
    });
}

export function processEntities<T>(logger: ILoggerBase, userData: States.IPersonDataStore, force, name: string,
    apiCall: (lastsync) => Promise<any>, processResult: (items: T[]) => void, progresscallback: any) {
    return processCall<T[]>(logger, userData, force, name, apiCall, processResult, progresscallback);
}
