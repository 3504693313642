/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { registerAsyncItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import type { States } from '../../../../services/services';
import { modules } from '../../../../routes/appmodules';
import { asyncBlocs } from '../../../../components/dynamicpage/blocscatalog';

export function networkingTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-networkingteasers" });
    return import(/* webpackChunkName: "mod-networkingteasers" */ '../module.networkingteasers');
}
modules["mod-networkingteasers"] = networkingTeasersModule;

export function userAgendaModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-agenda" });
    return import(/* webpackChunkName: "mod-agenda" */ '../module.agenda');
}
modules["mod-agenda"] = userAgendaModule;

export function networkingModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-networking" });
    return import(/* webpackChunkName: "mod-networking" */ '../module.networking');
}
modules["mod-networking"] = networkingModule;

export function meModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-me" });
    return import(/* webpackChunkName: "mod-me" */ '../module.me');
}
modules["mod-me"] = meModule;

export function syncUserModule() {
    return import(/* webpackChunkName: "mod-eventsyncuser" */ '../../sync/sync.user');
}
modules["mod-eventsyncuser"] = syncUserModule;

export function initUserEvent() {
    if (asyncBlocs["teasercontacts"]) {
        return;
    }

    asyncBlocs["teasercontacts"] = {
        module: "mod-networkingteasers",
        allowNetworking: true
    };
    asyncBlocs["teaserparticipants"] = {
        module: "mod-networkingteasers",
        allowNetworking: true
    };
    asyncBlocs["teaserrecommandations"] = {
        module: "mod-networkingteasers",
        allowNetworking: true
    };
    asyncBlocs["teasercontactrequests"] = {
        module: "mod-networkingteasers",
        user: true
    };
    asyncBlocs["myevent"] = {
        module: "mod-networkingteasers",
        registered: true
    };
    asyncBlocs["teaserexhibitoraccounts"] = "mod-networkingteasers";
    asyncBlocs["agenda"] = "mod-agenda";
    asyncBlocs["myagenda"] = "mod-agenda";
    asyncBlocs["attendeeslist"] = "mod-networking";

    registerAsyncItemTemplatePart("personpreferences", (n, d, s) => networkingTeasersModule(d, s));
    registerAsyncItemTemplatePart("contactitemactions", (n, d, s) => networkingTeasersModule(d, s));
    registerAsyncItemTemplatePart("contactitemactions.teaserexhibitorsteammembers", (n, d, s) => networkingTeasersModule(d, s));
    registerAsyncItemTemplatePart("entityactions.person", (n, d, s) => networkingTeasersModule(d, s));
    registerAsyncItemTemplatePart("contactitem", (n, d, s) => networkingTeasersModule(d, s));

}
