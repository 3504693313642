import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';
import { IEventRequests } from '@@services/apiaccessprovider.definition';
import { processCall } from './utils';
import {
    IRecommandedContactsRequest,
    getContactRecommandations,
    getFastContactRecommandations
} from '../../api/networking';

export function syncUserContactRecommandations(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    const hasData = false;
    return processCall<IRecommandedContactsRequest>(logger, userData, force, "contactrecommandations",
        (lastsync) => getContactRecommandations(eventRequests, lastsync), (reco) => {
            if (reco && reco.recommandedContacts) {
                return syncContactRecommandations(eventconf, userData, reco.recommandedContacts);
            }
        }, trackProgress).then(() => {
        return hasData;
    });
}

export function syncFastContactRecommandations(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    const hasData = false;
    return processCall<IRecommandedContactsRequest>(logger, userData, force, "contactrecommandations",
        () => getFastContactRecommandations(eventRequests), (reco) => {
            return syncContactRecommandations(eventconf, userData, reco.recommandedContacts);
        }, trackProgress).then(() => {
        return hasData;
    });
}

function syncContactRecommandations(eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore,
    contacts: Entities.IContactRecommandation[]) {
    userData.contactRecommandations.removeWhere(() => true);
    if (contacts && contacts.length) {
        contacts.forEach((c) => {
            userData.contactRecommandations.insert(c);
        });
    }
}
