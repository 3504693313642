import { logging } from '@inwink/logging';
import { Entities } from '@inwink/entities/entities';
import { userMessageModule, eventUserModule } from '@@routes/appmodules';
import { actionQueue } from '@@actionsqueue/index';
import { checkIfNetworkingAllowed } from '@@services/networkingservice';
import { States } from '@@services/services';
import { userDataActions } from './data';

const logger = logging.getLogger("Data");

let onlinestatewatcher = null;
let onUserSigned: () => void = null;
export function setOnUserSigned(callback: () => void) {
    onUserSigned = callback;
}
export const userBootstrapActions = {
    clearCurrentUser(reload = false) {
        return (dispatch, getState: () => States.IAppState) => {
            if (onlinestatewatcher) { onlinestatewatcher(); }

            onlinestatewatcher = null;
            dispatch({ type: "USER_CLEARCURRENT" });

            const state = getState();
            if (state.event.requestManagers) {
                state.event.requestManagers.userActions.clearCurrentUser(reload);
            } else if (state.rootwebsite.requestManager) {
                state.rootwebsite.requestManager.userActions.clearCurrentUser(reload);
            }
        };
    },

    onBoarding(i18nHelper?: Entities.i18nHelper) {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            const currentUser = storedata && storedata.user && storedata.user.currentUser && storedata.user.currentUser.detail;
            if (currentUser && currentUser.isRegistered && currentUser.allowNetworking == null) {
                const eventconf = storedata.event && storedata.event.detail && storedata.event.detail.configuration;
                const usenetworking = checkIfNetworkingAllowed(eventconf && eventconf.networking, currentUser, false);
                if (usenetworking && eventconf.networking.useOnboarding) {
                    const userWrkPage = storedata.event.data.templates.data
                        .filter((ct) => ct.application === "userwrk"
                        && ct.contentType === "page"
                        && ct.tinyUrl === "userprofile")[0] ?? 
                        storedata.event.data.templates.data
                            .filter((ct) => ct.application === "companion"
                            && ct.contentType === "page"
                            && ct.tinyUrl === "me")[0];

                    if (userWrkPage) {
                        eventUserModule().then((mod) => mod.meModule(dispatch, getState)).then((memod: any) => {
                            // userMessageActions.hideModal();
                            userMessageModule().then((mod) => {
                                mod.userMessageActions.showModal(i18nHelper,
                                    memod.OnBoarding,
                                    "",
                                    { networkingtemplate: userWrkPage },
                                    "usernetworkingonboardingmodal")(dispatch, getState);
                            });
                        });
                    }
                }
            }
        };
    },

    bootstrapCurrentUser() {
        return (dispatch, getState: () => States.IAppState) => {
            onlinestatewatcher = actionQueue.watchOnLineState(dispatch, getState);

            if (onUserSigned) {
                onUserSigned();
            }

            // if (currentUser && currentUser.isRegistered && currentUser.allowNetworking == null) {
            //     const eventconf = storedata.event && storedata.event.detail && storedata.event.detail.configuration;
            //     const usenetworking = checkIfNetworkingAllowed(eventconf && eventconf.networking, currentUser, false);
            //     if (usenetworking && eventconf.networking.useOnboarding) {
            //         const mepage = storedata.event.data.templates.data
            //             .filter((ct) => ct.application === "companion"
            //                 && ct.contentType === "page"
            //                 && ct.tinyUrl === "me")[0];

            //         if (mepage) {
            //             AppModules.meModule(dispatch, getState).then((mod: any) => {
            //                 // userMessageActions.hideModal();
            //                 userMessageActions.showModal(
            //                     mod.OnBoarding,
            //                     "",
            //                     { networkingtemplate: mepage },
            //                     "usernetworkingonboardingmodal"
            //                 )(dispatch, getState);
            //             });
            //         }
            //     }
            // }

            eventUserModule().then((mod) => mod.syncUserModule()).then((mod) => {
                const storedata = getState();
                const userdatastore = storedata?.user?.currentUser?.data;
                mod.userSync(storedata.event.requestManagers, userdatastore, true, dispatch, getState, (progress) => {
                    if (progress && progress.progressPercent === 100) {
                        // notifier qu'on a fini de synchro l'utilisateur (pour que le splash disparaisse à tous les coups...)
                        userBootstrapActions.notifyUserReady()(dispatch, getState);
                    }
                }).then(() => {
                    // @todo code mort ?
                    // startUserPolling(userdatastore, () => {
                    //     actions.dataChanged()(dispatch, getState);
                    // }, dispatch, getState);
                    // @todo le dispatch du userchanged est déjà fait dans le userSync
                    // userBootstrapActions.dataChanged()(dispatch);
                }, (err) => {
                    logger.error("error bootstrapping user", err);
                });
            });

            return Promise.resolve();
        };
    },

    dataChanged(userData?: States.IPersonDataStore) {
        return userDataActions.dataChanged(userData);
    },

    notifyUserReady() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (!state.user.isSyncReady) {
                dispatch({ type: "USER_SYNCREADY", payload: null });
            }
        };
    },

    logout(reload = true) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            let userActions;
            if (state.event?.eventid) {
                userActions = state.event.requestManagers?.userActions;
            } else if (state.community?.communityid) {
                userActions = state.community.requestManagers?.userActions;
            }
            return userActions?.signOut().then(() => {
                userBootstrapActions.clearCurrentUser(reload)(dispatch, getState);
            }, (err) => {
                userBootstrapActions.clearCurrentUser(reload)(dispatch, getState);
                logger.error("error loging out", err);
            });
        };
    },
};
