import type { Entities } from '@inwink/entities/entities';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';

export interface IRecommandedContactsRequest {
    recommandedContacts: any[];
    updated: Date | string;
}

export function getContactRecommandations(eventRequests: IEventRequests, fromDate?: Date)
    : Promise<IRecommandedContactsRequest> {
    return eventRequests.apiFront.getEventJson("networking/contactrecommandations" + (fromDate
        ? "?fromDate=" + fromDate.toISOString() : ""));
}

export function getFastContactRecommandations(eventRequests: IEventRequests): Promise<IRecommandedContactsRequest> {
    return eventRequests.apiFront.getEventJson("networking/fastcontactrecommandations");
}

export function updateAllowNetworking(eventRequests: IEventRequests, allownetworking: boolean) {
    return eventRequests.apiFront.postEventJson("networking/updateallownetworking", JSON.stringify({
        allowNetworking: allownetworking
    }), defaultPostHeaderForJsonData);
}

export function updateNetworkingPreferences(eventRequests: IEventRequests, preferences) {
    return eventRequests.apiFront.postEventJson("networking/updatepreferences",
        JSON.stringify(preferences), defaultPostHeaderForJsonData);
}

export function excludeContactRecommandation(eventRequests: IEventRequests, personId: string) {
    return eventRequests.apiFront.postEventJson(`networking/exclude-contactrecommandation`,
        JSON.stringify({ personId: personId }), defaultPostHeaderForJsonData);
}

export function search(eventRequests: IEventRequests, searchtext: string) {
    return eventRequests.apiFront.getEventJson<Entities.IPerson[]>(`networking/search?search=${encodeURIComponent(searchtext)}`);
}

export function getPersonDetail(eventRequests: IEventRequests, personId: string) {
    return eventRequests.apiFront.postEventJson(`networking/person/${personId}`, JSON.stringify({
        selects: {
            $all: true,
            animatedSessions: { $all: true },
            exhibitorAccounts: { $all: true }
        }
    }), defaultPostHeaderForJsonData).then((p: any) => {
        const person = p;
        person.sessions = person.animatedSessions;
        return person;
    });
}
